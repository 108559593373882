import { string, object } from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { articleDate, removingWordFree } from '../../helpers';

import * as styles from './article-card.module.scss';

function ArticleCard({
  title, uri, date, image, wpImage, content,
}) {
  const contentCutted = `<p>${content.slice(0, 138)}...</p>`;

  const getArticleImage = () => {
    if (image?.gatsbyImage) {
      return (
        <GatsbyImage
          image={getImage(image?.gatsbyImage)}
          alt={image?.altText}
          className={styles.image}
        />
      );
    } if (wpImage?.sourceUrl) {
      return (
        <img
          className={styles.wpImage}
          src={wpImage.sourceUrl}
          alt={wpImage.altText}
        />
      );
    }
    return (
      <StaticImage
        className={styles.image}
        src="./article-card-default.jpg"
        alt=""
      />
    );
  };
  return (
    <Link to={uri}>
      <article className={styles.cardContainer}>
        {getArticleImage()}
        <div className={styles.content}>
          <h4 className={styles.title}>
            <span>
              {removingWordFree(title)}
            </span>
          </h4>
          <p className={styles.date}>
            {articleDate(date.date, date.dateModified, date.videoName)}
          </p>
          <div
            className={styles.desc}
            dangerouslySetInnerHTML={{ __html: removingWordFree(contentCutted) }}
          />
        </div>
        {' '}
      </article>
    </Link>
  );
}

ArticleCard.propTypes = {
  title: string,
  uri: string,
  date: object,
  image: object,
  wpImage: '',
  content: string,
};

ArticleCard.defaultProps = {
  title: '',
  uri: '',
  date: {},
  image: {},
  wpImage: '',
  content: {},
};

export { ArticleCard };
