import {
  bool, object, string,
} from 'prop-types';
import { Fragment } from 'react';
import { Wrap } from '../sections-wrap';
import { ArticleCard } from '../article-card';
import { AskLawyerCard } from '../ask-lawyer-card';
import * as styles from './search.module.scss';
import { Select } from '../common/custom-select';
import { sortArticlesByDate } from '../../helpers';
import { Pagination } from '../common/pagination';
import * as selectStyles from '../common/custom-select/select.module.scss';

function Search({
  att, data, pageContext, showAskLawyerCard,
}) {
  const page = pageContext?.currentPage;
  const numPages = pageContext?.numPages;

  const handleChange = (event) => {
    window.location.href = event;
  };
  let allItems = att === 'Category'
    ? data?.allAtts?.nodes?.filter((category) => category.wpParent?.node.name === 'Colorado'
   || category.wpParent?.node.wpParent?.node.name === 'Colorado')
    : data?.allAtts?.nodes.filter((tag) => !!tag.contentNodes.nodes.length);
  allItems = allItems?.sort((a, b) => (a.name?.localeCompare(b.name)));

  const handleFirstOptionChange = () => {
    handleChange('/blog');
  };

  let articles = data.posts?.nodes;
  articles = sortArticlesByDate(articles);

  return (
    <main>
      <div className={styles.pageContainer}>
        <Wrap>
          <main className={styles.dataContainer}>
            <header>
              <div className={styles.headerContainer}>
                {att === 'Tag'
                  ? (
                    <div className={styles.tagHeader}>
                      <h2>Legal Topic</h2>
                      <h1>{data.wpPage.name}</h1>
                    </div>
                  )
                  : (
                    <h1 className={styles.title}>
                      <span>{data?.wpPage?.name ? data?.wpPage?.name : 'R&H BLOG'}</span>
                    </h1>
                  )}
                <div className={styles.mobileContainer}>
                  <Select
                    onChange={handleChange}
                    value={data.wpPage.name}
                    onChangeFirstOption={handleFirstOptionChange}
                    firstOption="All"
                    label={att}
                  >
                    {allItems?.map((item) => (
                      <button
                        onClick={() => handleChange(item.uri)}
                        type="button"
                        className={item.name === data.wpPage.name
                          ? selectStyles.selected
                          : selectStyles.item}
                      >
                        <p className={selectStyles.text}>{item.name}</p>
                      </button>
                    ))}
                  </Select>
                </div>
              </div>
              {page > 1 && (
              <h4 className={styles.pageNumber}>
                Page
                {' '}
                {page}
                {' '}
                of
                {' '}
                {numPages}
              </h4>
              )}
            </header>
            <div className={styles.teamGrid}>
              {articles?.map((article, i) => (
                <Fragment key={article.title}>
                  {i === 4 && showAskLawyerCard && (
                  <AskLawyerCard />
                  )}
                  <ArticleCard
                    key={article.title}
                    title={article.title}
                    uri={article.uri}
                    date={
                      {
                        date: article.date,
                        dateModified: article.schemaMarkup.eaPostSchemaUploadDate,
                        videoName: article.schemaMarkup.eaPostSchemaVideoName,
                      }
                    }
                    image={article?.featuredImage?.node}
                    content={article?.excerpt}
                  />
                </Fragment>
              ))}
            </div>
            <Pagination
              page={page}
              numPages={numPages}
              isLink
              to={data.wpPage?.uri}
            />
          </main>
        </Wrap>
      </div>
    </main>
  );
}

Search.propTypes = {
  att: string,
  data: object,
  pageContext: object,
  showAskLawyerCard: bool,
};

Search.defaultProps = {
  att: '',
  data: {},
  pageContext: {},
  showAskLawyerCard: false,
};

export { Search };
