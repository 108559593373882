import { Link } from 'gatsby';
import * as styles from './ask-lawyer-card.module.scss';

function AskLawyerCard() {
  return (
    <article className={styles.askLawyerCard}>
      <p className={styles.icon}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 23">
          <g>
            <path d="M17.58,15.57 C19.18485,14.934351 20.6023439,13.9021953 21.7,12.57 C23.7145604,10.1851703 23.7145604,6.69482966 21.7,4.31 C20.6023439,2.97780472 19.18485,1.94564902 17.58,1.31 C15.7800211,0.56470731 13.8480779,0.190563378 11.9,0.21 C9.9519471,0.190838145 8.0200583,0.56497154 6.22,1.31 C4.61514998,1.94564902 3.19765614,2.97780472 2.1,4.31 C1.13140291,5.46900634 0.597398095,6.92956255 0.59,8.44 C0.601716949,9.71457019 0.99916077,10.9557105 1.73,12 C2.54396379,13.1722178 3.61440883,14.1434842 4.86,14.84 C4.75333333,15.1 4.64333333,15.3366667 4.53,15.55 C4.41571321,15.7683511 4.28182987,15.9758703 4.13,16.17 L3.77,16.64 C3.68333333,16.7533333 3.54333333,16.91 3.35,17.11 L2.98,17.51 C2.98,17.51 2.98,17.51 2.92,17.58 C2.86,17.65 2.85,17.67 2.85,17.66 C2.85,17.65 2.85,17.66 2.79,17.74 L2.73,17.83 L2.73,17.91 C2.72507125,17.9431511 2.72507125,17.9768489 2.73,18.01 C2.72735032,18.0432807 2.72735032,18.0767193 2.73,18.11 C2.72475948,18.1431274 2.72475948,18.1768726 2.73,18.21 C2.74529822,18.3418777 2.80953368,18.4632113 2.91,18.55 C3.00041813,18.6322034 3.11780988,18.6784486 3.24,18.68 C3.70420778,18.6173866 4.16483357,18.5306021 4.62,18.42 C6.22356601,18.0128269 7.73870436,17.3145753 9.09,16.36 C10.0238533,16.5297103 10.9708568,16.6167142 11.92,16.62 C13.857971,16.6528767 15.7827733,16.295802 17.58,15.57 Z" />
            <path d="M28.24,16.17 C29.7846894,13.9746935 29.7605655,11.0396195 28.18,8.87 C27.3230383,7.6728131 26.2003483,6.69045936 24.9,6 C25.572065,8.17830021 25.315168,10.5374107 24.19,12.52 C23.4179563,13.8661956 22.3638531,15.0294616 21.1,15.93 C19.8117045,16.8511382 18.3786765,17.5507533 16.86,18 C15.2384912,18.4876687 13.5532231,18.7303474 11.86,18.72 C11.54,18.72 11.07,18.7 10.45,18.66 C12.7128474,20.105807 15.3554611,20.8439283 18.04,20.78 C18.9891407,20.7766622 19.9361396,20.6896588 20.87,20.52 C22.5981376,21.7634443 24.608544,22.5572971 26.72,22.83 C26.8466297,22.8411247 26.9725014,22.801565 27.07,22.72 C27.1794113,22.6320334 27.2538701,22.5079355 27.28,22.37 C27.28,22.31 27.28,22.27 27.28,22.27 C27.28,22.27 27.28,22.27 27.28,22.17 L27.28,22.07 L27.28,21.99 L27.22,21.9 L27.16,21.82 L27.09,21.74 L27,21.67 L26.63,21.27 C26.4366667,21.0633333 26.2966667,20.9066667 26.21,20.8 L25.85,20.33 C25.6981251,20.1359023 25.564239,19.9283789 25.45,19.71 C25.3366667,19.4966667 25.2266667,19.26 25.12,19 C26.3600801,18.3039833 27.426687,17.3365161 28.24,16.17 Z" />
          </g>
        </svg>
      </p>
      <h4> Don't see what you're looking for?</h4>
      <div className={styles.buttonContainer}>
        <Link to="/questions/" className={styles.button}>
          Ask a lawyer &gt;
        </Link>
      </div>
    </article>
  );
}

export { AskLawyerCard };
