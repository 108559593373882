import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { Search } from '../components/search-by-att';

function TagTemplate({ data, pageContext }) {
  return (
    <Search att="Tag" data={data} pageContext={pageContext} />
  );
}

export const query = graphql`
query wpTagTemplate($uri: String, $tag: Int, $limit: Int, $skip: Int) {
  allAtts: allWpTag {
    nodes {
      uri
      name
      databaseId
      contentNodes{
        nodes{
          uri
        }
      }
    }
  }
  wpPage: wpTag(uri: {eq: $uri}) {
    uri
    name
    databaseId
    ...SeoTag
  }
  posts: allWpPost(
    filter: {tags: {nodes: {elemMatch: {databaseId: {eq: $tag}}}}}
    limit: $limit
    skip: $skip
    sort: {order: DESC, fields: date}
  ) {
    nodes {
      title
      date
      schemaMarkup {
        eaPostSchemaUploadDate
        eaPostSchemaVideoName
      }
      excerpt
      uri
      featuredImage {
        node {
          gatsbyImage(width:300)
        }
      }
    }
  }
}
`;

TagTemplate.propTypes = {
  data: object,
  pageContext: object,
};

TagTemplate.defaultProps = {
  data: {},
  pageContext: {},
};

export default TagTemplate;
export { Head } from '../components/seo/seo';
